import { ArrowLeftOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Flex, Input, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useFetchREOutcomesUsersList,
  useREOutcomesStore,
} from "../../../stores/REOutcomesStore";

const REWARD_TYPE = {
  3: "Points",
  6: "Message",
  7: "Cashback",
};

const REOutcomesUsersTable = () => {
  const [queryParams, setQueryParams] = useState({
    pageNo: 1,
    pageSize: 10,
    sort: "desc",
    pattern: "",
  });

  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    setQueryParams((prevParams) => ({
      ...prevParams,
      pattern: debouncedSearchTerm,
      pageNo: 1,
    }));
  }, [debouncedSearchTerm]);

  const columns = [
    {
      title: "FULL NAME",
      dataIndex: "fullName",
      key: "fullName",
      render: (value) => (value ? value : "-"),
    },
    {
      title: "MOBILE NUMBER",
      dataIndex: "mobileNumber",
      key: "mobileNumber",
      render: (value) => (value ? value : "-"),
    },
    {
      title: "REWARD RECEIVED ON",
      dataIndex: "rewardReceivedOn",
      key: "rewardReceivedOn",
      render: (value) => (value ? value : "-"),
    },
    {
      title: "REWARD TYPE",
      dataIndex: "rewardType",
      key: "rewardType",
      render: (value) => (value ? REWARD_TYPE[value] : "-"),
    },
    {
      title: "REWARD VALUE",
      dataIndex: "rewardValue",
      key: "rewardValue",
      render: (value, data) => (data.rewardType == 7 ? `₹ ${value}` : value),
    },
  ];

  const { id: campaignId } = useParams();
  const navigate = useNavigate();

  const getREOutcomesUsers = useFetchREOutcomesUsersList();

  const reOutcomesUsersListData = useREOutcomesStore(
    (state) => state.reOutcomesUsersListData
  );

  const handleTableChange = (pagination) => {
    // handle pagination
    setQueryParams((prev) => ({ ...prev, pageNo: pagination.current }));
  };

  useEffect(() => {
    getREOutcomesUsers(campaignId, queryParams);
  }, [queryParams]);

  return (
    <>
      <Flex className="mb-[16px]" justify="space-between">
        <Input
          style={{ maxWidth: "350px" }}
          allowClear
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search by Mobile no. or Name"
          prefix={<SearchOutlined />}
          size="large"
        />
        <Flex justify="flex-end" align="center" gap="16px">
          <Button
            icon={<ArrowLeftOutlined />}
            iconPosition="start"
            size="large"
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        </Flex>
      </Flex>
      <Table
        dataSource={reOutcomesUsersListData?.docs}
        columns={columns}
        pagination={{
          ...queryParams,
          total: reOutcomesUsersListData?.totalEntries,
          current: queryParams.pageNo,
        }}
        onChange={handleTableChange}
        scroll={{ x: 750 }}
      />
    </>
  );
};

export default REOutcomesUsersTable;
