export const apiRoutes = {
  auth: {
    loginAdmin: "/auth/admin",
    setPassword: "/companyUser/verify",
    resetPassword: "/auth/resetPassword",
    checkLink: "/auth/verifyCode/",
    loginMallUser: "/auth/mallTeam",
    forgotPassword: "/auth/forgotPassword",
    resetPassword: "/auth/resetPassword",
    requestOTP: "/auth/requestOtp",
    loginAppUsers: "/auth/appLogin",
    getSuperAdmin: "/profile/1",
    addSuperAdmin: "/addSuperAdmin",
    getSuperUserList: "/getSuperAdmin",
    getConstants: "/constants/getConstants",
  },
  modules: {
    constants: "/constants/getConstants",
    getModules: "/modules/getModules",
    menuObjects: "/modules/getMenuObject",
  },
  loyaltyOnboarding: {
    updatePoints: "/settings/loyalty/updatePointsXp",
    getPoints: "/settings/loyalty/byCompany",
    addPoints: "/settings/loyalty/addPointsXp",
  },
  usersOnboarding: {
    addUser: "/settings/addUser",
    verifyUser: "/companyUser/verify",
    resendVerfication: "/companyUser/resendVerification",
    getAllUser: "/settings/getUser", //for single user add user id
    updateUser: "/settings/updateUser",
    deleteUser: "/settings/deleteUser",
  },
  instanceOnboarding: {
    instance: "/settings/instance", //get, post, put methods allowed
    getInstanceList: "/settings/instance/list",
    enableInstance: "/settings/instance/enableInstance",
    disableInstance: "/settings/instance/disableInstance",
    updateDomainName: "/settings/settings/updateDomain",
    addTechnicalDetails: "/settings/instance/addTechnicalDetails",
    updateTechnicalDetails: "/settings/instance/updateTechnicalDetails",
    getTechnicalDetails: "/settings/instance/updateTechnicalDetails",
  },
  tiersOnboarding: {
    tierList: "/settings/tier", //get, post, put, delete methods allowed
    tierCutOnXP: "/settings/tier/cutOnXp",
  },
  primaryEarnMethod: {
    getPrimaryEarnMethod: "/settings/instance/primaryEarnMethod",
    getPrimaryEarnMethodList: "/settings/instance/primaryEarnMethodList",
  },
  banner: {
    banner: "/banner", //get, post, put, delete  methods allowed
    getRedirectionType: "/banner/redirectionType",
    checkAvailibility: "/banner/availability/",
    getCount: "/banner/count",
    getRedirectionTypeSublist: "/banner/redirectionType/subTypeList",
  },
  profile: {
    getUserProfile: "/profile",
    postUserProfile: "/profile/updateUser",
  },
  customers: {
    getCustomers: "/customers",
    getIssues: "/customers/issues",
    getUtilization: "/customers/getCustomerUtilization",
    getTransactions: "/customers/transactions",
    getCoupons: "/customers/coupons",
    getRewards: "/customers/rewards",
    getActivities: "/customers/activities",
    getScanGraph: "/customers/customerScanGraph",
    getCustomerLoyaltyGraph: "/customers/customerLoyaltyGraph",
    getCustomerPointsGraph: "/customers/customerPointsGraph",
    getCustomerTransactionList: "/customers/getCustomerTransactionList",
    getScratchCardCountList: "/customers/scratchCard",
    getScanList: "/customers/scans",
  },
  teamManagement: {
    getUsers: "/users",
    getRoles: "/users/roles",
    getSingleRole: "/users/roles/singleRole",
    updateRole: "/users/roles/updateUserRole",
    resendVerification: "/users/resendVerification",
    addHandlerUser: "/users/addHandlerUser",
  },
  feed: {
    getNewsFeed: "/newsFeed", //get, post, put, delete methods allowed along with id as param
    getTags: "/newsFeed/tag",
    getActivity: "/newsFeed/activity",
    putNewsFeed: "/newsFeed",
    putNewsTag: "/newsFeed/tag",
  },
  deepLink: {
    deepLink: "/deepLink",
  },
  referralRevamp: {
    referralRevamp: "/referral",
    referralRevampCode: "/referral/code",
    referralSchedule: "/referral/schedule",
    referralUpdateStatus: "/referral/updateStatus",
    referralUsers: "/referral/users",
    coupon: "/coupon",
    rewards: "/rewards",
    events: "/settings/events",
  },
  branding: {
    getBranding: "/branding",
  },
  smsDetails: {
    getSmsDetails: "/settings/smsGateway",
    getPartnerConfiguration: "/settings/getPartnerConfiguration",
    addOrUpdateSmsDetails: "/settings/smsGateway",
  },
  technicalDetails: {
    getTechnicalDetails: "/settings/instance/getTechnicalDetails",
    addTechnicalDetails: "/settings/instance/addTechnicalDetails",
    updateTechnicalDetails: "/settings/instance/updateTechnicalDetails",
  },
  staticPage: {
    getStaticPage: "/staticPage/",
  },
  retailers: {
    getRetailers: "/retailers",
  },
  branding: {
    getBranding: "/branding",
  },
  smsDetails: {
    getSmsDetails: "/settings/smsGateway",
    getPartnerConfiguration: "/settings/getPartnerConfiguration",
    addOrUpdateSmsDetails: "/settings/smsGateway",
  },
  technicalDetails: {
    getTechnicalDetails: "/settings/instance/getTechnicalDetails",
    addTechnicalDetails: "/settings/instance/addTechnicalDetails",
    updateTechnicalDetails: "/settings/instance/updateTechnicalDetails",
  },
  staticPage: {
    getStaticPage: "/staticPage/",
  },
  customerDelight: {
    getCoupon: "/coupon",
    getReward: "/rewards",
    getScratchCard: "/scratchCard",
    getSpinWheel: "/spinWheel",
    postDelightTransfer: "/delight/",
    postRequestOTP: "/userRequestOtp",
    postDownloadReport: "/reportsManagement/createReport",
  },
  scanAndEarnAriston: {
    getScanList: "/getScanEarnHistory",
    getSellersList: "/getScanEarnHistory/sellerName",
    getProductNameList: "/getScanEarnHistory/productName",
    verifyScan: "/getScanEarnHistory/verfifyBarCode",
    handleAcceptScan: "/getScanEarnHistory/updateScanDetails",
  },
  refuteManagement: {
    getScanList: "/customerScans",
    retailerList: "/retailers",
    scanHistory: "/customers/scans",
    handleAcceptRefute: "/customerScans",
  },
  rewards: {
    getRewards: "/rewards", // post,put,delete available with id
    getCohort: "/rewards/cohort",
    getRewardUsage: "/rewardUsage", // use with id
    addCode: "/rewards/codes", // to update add id between rewards and codes
    getPriceChange: "/rewards/priceChanges", // use with id
  },
  instanceList: {
    getInstanceList: "/settings/instance/list/",
  },
  tier: {
    getTiers: "/tiers",
  },
  shopAndWin: {
    getScanCampaign: "/scanCampaign",
    getRetailerCategory: "/retailers/category/",
    getRetailers: "/retailers/",
    getScanCampaignUser: "/scanCampaign/users",
  },
  spinTheWheel: {
    spinTheWheel: "/spinWheel",
    updateSpinWheel: "/spinWheel/updateSpinWheel",
    spinUsage: "/spinWheel/spinUsage",
    tiers: "/tiers",
  },
  scratchCard: {
    scratchCard: "/scratchCard",
    updateScratchCard: "/scratchCard/updateScratchCard",
    cardUsage: "/scratchCard/cardUsage",
    tiers: "/tiers",
  },
  instanceList: {
    getInstanceList: "/settings/instance/list",
  },
  addCompany: "/settings/instance",
  cashbackPayouts: {
    getCashback: "/cashbackPayouts",
    putCashback: "/cashbackPayouts",
  },
  survey: {
    getSurveyList: "/survey/getSurvey/list",
    endSurvey: "/survey/endSurvey",
    deleteSurvey: "/survey/deleteSurvey",
    addToLibrary: "/imageLibrary/add",
    getTier: "/tier",
    postSurvey: "/survey/",
    getSingleSurvey: "/survey/getSingleSurvey",
    getUserData: "/survey/userData",
  },
  qrCampaign: {
    getQrCampaign: "/qrCampaign",
    getQrUsers: "/qrCampaign/qrScans",
    addQrCodes: "/qrCampaign/addQrCodes",
    deActivateQr: "/qrCampaign/deActivate",
    activateQr: "/qrCampaign/activate",
  },
  coupon: {
    getCoupons: "/coupon",
    getUsers: "/coupon/getCouponUsage",
    addCode: "/coupon/codes",
    updateCode: "/coupon/updateCouponCode",
    users: "/coupon/getCouponUsage",
    postCoupon: "/coupon/addCouponPro",
    putCoupon: "/coupon/updateCouponPro",
  },
  reOutcomes: {
    reOutcome: "/reOutcome",
    reOutcomes: "/reOutcomes",
    status: "/reOutcomes/status",
    users: "/reOutcomes/users",
  },
};

export const refuteStatusTags = {
  0: { color: "red", text: "Rejected" },
  1: { color: "green", text: "Accepted" },
  2: { color: "blue", text: "Received" },
  3: { color: "green", text: "Accepted" },
  4: { color: "red", text: "Rejected" },
  5: { color: "green", text: "Accepted" },
  6: { color: "blue", text: "Received" },
  7: { color: "blue", text: "Received" },
};

export const scanStatusTags = {
  1: { color: "yellow", text: "Pending" },
  2: { color: "green", text: "Accepted" },
  3: { color: "red", text: "Rejected" },
};

export const qrStatusTags = {
  1: "yellow",
  2: "green",
  3: "red",
  4: "red",
  5: "red",
  0: "yellow",
};

export const refuteMenuItems = [
  {
    label: "Scan Details",
    key: "scan-details",
  },
  {
    label: "User's History",
    key: "user-history",
  },
];
export const scanMenuItems = [
  {
    label: "Scan Details",
    key: "scan-details",
  },
];

export const getNameInitials = (name) => {
  if (name) {
    const words = name.split(" ");
    if (words.length >= 2) {
      return words[0][0].toUpperCase() + words[1][0].toUpperCase();
    }
    return name.substring(0, 2).toUpperCase();
  }
};

export const getInitials = (name) => {
  if (name) {
    const words = name.split(" ");
    if (words.length >= 2) {
      return (
        <div className="logo">
          {words[0][0].toUpperCase() + words[1][0].toUpperCase()}
        </div>
      );
    }
    return <div className="logo">{name.substring(0, 2).toUpperCase()}</div>;
  }
};
export const segmentTypes = {
  ageGroup: "Age Group",
  gender: "Gender",
  dateOfBirth: "Birthday",
  anniversary: "Anniversary",
  tiers: "Tiers",
};

export const rewardCategorizations = [
  {
    title: "Marketplace",
    description:
      "Mapped the audience who scan the bills to redeem points which further help them earn gems & are rewarded post completion of every milestone.",
  },
  {
    title: "Bucket",
    description:
      "Mapped to particular set of customers to earn gems associated with either retailer or promotional activities.",
  },
  {
    title: "Delight",
    description:
      "Should be used for transferring rewards as a delight directly to the users without any conditions.",
  },
  { title: "Scratch Card" },
  { title: "Spin Wheel" },
];

export const couponCategorizations = [
  {
    title: "Marketplace",
    description:
      "Mapped the audience who scan the bills to redeem points which further help them earn gems & are rewarded post completion of every milestone.",
  },
  {
    title: "Activation",
    description: "Activation text",
  },
  {
    title: "Bucket",
    description:
      "Mapped to particular set of customers to earn gems associated with either retailer or promotional activities.",
  },
  {
    title: "Delight",
    description:
      "Should be used for transferring rewards as a delight directly to the users without any conditions.",
  },
  { title: "Scratch Card" },
  { title: "Spin Wheel" },
  { title: "Quiz" },
];

export const toolbarConfig = {
  display: [
    "INLINE_STYLE_BUTTONS",
    "BLOCK_TYPE_BUTTONS",
    "LINK_BUTTONS",
    "BLOCK_TYPE_DROPDOWN",
  ],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD", className: "custom-css-class" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Heading Large", style: "header-one" },
    { label: "Heading Medium", style: "header-two" },
    { label: "Heading Small", style: "header-three" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ],
};

export const scanStatusMapping = {
  all: "all",
  pending: "1",
  accepted: "2",
  rejected: "3",
};

export const refuteStatusMapping = {
  all: "all",
  rejected: "0",
  accepted: "1",
  pending: "2",
};
