import { useState, useCallback } from "react";
import { create } from "zustand";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export const sessionStore = create((set) => ({
  isSessionExpired: false,

  setIsSessionExpired: (isExpired) => {
    set({ isSessionExpired: isExpired });
  },
}));

const useApi = (baseUrl = process.env.REACT_APP_BASE_URL) => {
  const navigate = useNavigate();
  const setIsSessionExpired = sessionStore(
    (state) => state.setIsSessionExpired
  );
  const [state, setState] = useState({
    data: null,
    loading: false,
    error: null,
  });

  const makeRequest = useCallback(async (method, endpoint, data, config) => {
    setState((prev) => ({ ...prev, loading: true, error: null }));

    try {
      const response = await axios({
        method,
        url: `${baseUrl}${endpoint}`,
        data,
        ...config,
      });

      setState({
        data: response.data,
        loading: false,
        error: null,
      });

      return response.data;
    } catch (error) {
      if (error.status == 401 && sessionStorage.getItem("authToken")) {
        setIsSessionExpired(true);
        navigate("/login");
        sessionStorage.removeItem("authToken");
        sessionStorage.removeItem("instanceId");
        sessionStorage.removeItem("loginData");
      }
      setState({
        data: null,
        loading: false,
        error: error.message || "An error occurred",
      });
      throw error;
    }
  }, []);

  const get = useCallback(
    (endpoint, config) => makeRequest("GET", endpoint, undefined, config),
    [makeRequest]
  );

  const post = useCallback(
    (endpoint, data, config) => makeRequest("POST", endpoint, data, config),
    [makeRequest]
  );

  const put = useCallback(
    (endpoint, data, config) => makeRequest("PUT", endpoint, data, config),
    [makeRequest]
  );

  const del = useCallback(
    (endpoint, config, data) => makeRequest("DELETE", endpoint, data, config),
    [makeRequest]
  );

  return {
    ...state,
    get,
    post,
    put,
    delete: del,
  };
};

export default useApi;
