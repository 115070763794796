import { Breadcrumb, Card, Typography } from "antd";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  useFetchREOutcomes,
  useREOutcomesStore,
} from "../../stores/REOutcomesStore";
import REOutcomesUsersTable from "../templates/REOutcomesTables/REOutcomesUsersTable";

const { Title } = Typography;

const ViewREOutcomesUsers = () => {
  const { id: campaignId } = useParams();

  const getREOutcomesById = useFetchREOutcomes();
  const reOutcomesData = useREOutcomesStore((state) => state.reOutcomesData);
  const setState = useREOutcomesStore((state) => state.setState);

  useEffect(() => {
    getREOutcomesById(campaignId);

    return () => {
      setState({
        reOutcomesData: null,
        isREOutcomesLoading: false,
        isREOutcomesSuccess: false,
        isREOutcomesError: false,
      });
    };
  }, []);
  return (
    <>
      <Breadcrumb
        className="pb-6"
        items={[
          {
            title: "Home",
            href: "/",
          },
          {
            title: "RE Outcomes",
            href: "/re-outcomes",
          },
          {
            title: "View RE Outcomes Users",
            href: `/re-outcomes/view-users/${campaignId}`,
          },
        ]}
      />
      <Title level={2}>User Details</Title>
      <Card bordered={false}>
        <REOutcomesUsersTable />
      </Card>
    </>
  );
};

export default ViewREOutcomesUsers;
